
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Collapsible from '../../components/util/Collapsible.vue';
import CollapsibleButton from '../../components/util/CollapsibleButton.vue';
import PersonList from '../../components/Person/PersonList.vue';
import { mdiChevronDoubleLeft, mdiAccountGroup   } from '@mdi/js';

export default defineComponent({
  components: {
    Collapsible,
    CollapsibleButton,
    PersonList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const distro = ref({});
    const currentSkill = ref(router.currentRoute.value.params.id);
    const loaded = ref(false);
    const skillinfo = ref({});
    const allPersons = ref([]);
    const clazz = ref("");

    const load = () => {
      store.dispatch('fetchPointDistroForSkill', currentSkill.value).then((res) => {
        distro.value = res;
        loaded.value = true;
      });

      store.dispatch('fetchSkillInfo', currentSkill.value).then((res: SkillInfo) => {
        skillinfo.value = res;
        switch(res.group){
          case "Neuentwicklung":
            clazz.value = "neuentwicklung";
            break;
            case "Umsetzung":
            clazz.value = "umsetzung";
            break;
            case "Sonstige":
            clazz.value = "sonstige";
            break;
        }
      });

      store.dispatch('fetchCompententForSkill', currentSkill.value).then((res) => {
        allPersons.value = res;
      });
    };

    load();

    return { distro, loaded, skillinfo, allPersons, mdiChevronDoubleLeft, mdiAccountGroup,clazz   };
  },
});
