<style lang="scss">
#analysedetails {
  $barchart-height: 430px;
  position: relative;
  .headline-h2 {
    margin-bottom:20px !important;

  }
  .back {
    position: absolute;
    top: -10px;
    left: 0;
    background:none;
    color:#666;
    padding-right:25px;
    border:1px solid #eee;
    padding-bottom:10px;
     
    svg {
      width:30px;
      height:30px;
      margin-right:5px;
      position:relative; 
      top:-1px;
      left:-5px;
      fill:#666;
    }
    &:hover {
      color:#fff;
      border:1px solid #fff;
      svg {
        fill:#fff;
      }
    }
  }
  .btn-center-mitarbeiter-collapse {
    margin:80px 0;
    text-align:center;
    .btn {
     
      background:none;
      
      color:#333;
      border:1px solid #eee;
      padding:15px 25px 15px 25px;
      
      &:hover {
        background-color:$c-umsetzung;
        border:1px solid #fff;
        color:#fff;
      }
    }
  }
  .headline-h2 {
    margin-top: 0;
    margin-bottom: 40px;
    font-size:24px;
    letter-spacing: 1px;
    text-transform: uppercase;

    span {
      display: inline-block;
    }
  }
  .col {
    display: inline-block;
  }
  .progress-bar-outer {
    
    //border: 1px solid #eee;
   
    padding: 0px 25px;
    width:60%;
    margin:0 auto 0 auto;
    $heightprogress: 40px;
    .progress-bar-inner {
      position: relative;
      height: $heightprogress;
      .bar-100perc {
        width: 100%;
        height: $heightprogress;
        background: rgb(218, 218, 218);
        background: linear-gradient(0deg, rgba(218, 218, 218, 1) 0%, rgba(238, 238, 238, 1) 100%);
        position: absolute;
        border-radius: 25px;
        left: 0;
        top: 0;
        z-index: 1;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.05);
        .nr {
          display: inline-block;
          position: absolute;
          right: 20px;
          line-height: $heightprogress;
          font-weight: 700;
          top: 0;
          color: #666;
        }
      }
      .bar-total {
        position: absolute;
        height: $heightprogress;
        left: 0;
        top: 0;
        z-index: 2;
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        overflow: hidden;
        &:after {
          content: '';
          display: block;
          width: 100%;
          height: 100%;
          //background: rgb(232, 209, 122);
          //background: linear-gradient(180deg, rgba(232, 209, 122, 1) 0%, rgba(212, 190, 108, 1) 100%);
          animation-name: animateBar;
          animation-duration: 6s;
          animation-timing-function: ease-in-out;
          border-top-left-radius: 25px;
          border-bottom-left-radius: 25px;
          border-radius: 25px;
        }
        .nr {
          display: inline-block;
          position: absolute;
          right: 20px;
          line-height: $heightprogress;
          font-weight: 700;
          top: 0;
          animation-name: animateBarText;
          animation-duration: 6s;
          animation-timing-function: ease-in-out;
          color:#fff;
        }
      }
      .neuentwicklung {
        &:after {
          background:$c-neuentwicklung;
        }
       
      }
      .umsetzung {
        &:after {
          background:$c-umsetzung;
        }
      }
      .sonstige {
        &:after {
          background:$c-sonstige;
        }
      }

    }
    @keyframes animateBar {
      0% {
        width: 0%;
      }
      100% {
        width: 100%;
      }
    }
    @keyframes animateBarText {
      0% {
        opacity: 0;
      }
      90% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  .barchart-skill-outer {
    
    display: block;
    width: 80%;
    margin:0 auto;
    padding: 0px 15px;
    .barchart-skill-inner {
      width: 90%;
      left: 5%;
      position: relative;
      padding-left: 40px;
      .txt-box-y-ach {
        position: absolute;
        left: -70px;
        top: 0;
        @media (max-width: 900px) {
          left: -30px;
          top: -0px;
        }
        span {
          display: inline-block;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 3px 5px;
          background: #eee;
        }
      }
      .txt-box-x-ach {
        position: absolute;
        right: 0px;
        bottom: -45px;
        @media (max-width: 900px) {
          right: -40px;
        }
        @media (max-width: 780px) {
          position: relative;
          text-align: center;
          right: 0;
          top: 70px;
        }
        span {
          display: inline-block;
          font-size: 12px;
          text-transform: uppercase;
          letter-spacing: 1px;
          padding: 3px 5px;
          background: #eee;
        }
      }
      @media (max-width: 768px) {
        left: 1%;
        width: 100%;
        padding-left: 0;
      }
      .skala-left {
        position: absolute;
        height: 100%;
        z-index: -1;
        width: 100%;
        left: 0;
        bottom: 0px;

        .skala-box {
          border-bottom: 1px solid #eee;
          height: 14.28%;
          position: relative;
          .nr {
            display: inline-block;
            position: absolute;
            left: -30px;
            bottom: -9px;
            width: 20px;
            text-align: center;
            line-height: 20px;
            height: 20px;
          }
        }
      }
      .box {
        display: inline-block;
        width: 25%;
        position: relative;
        .nr-hori {
          width: 100%;
          position: absolute;
          left: 0;
          font-weight: 700;
          bottom: -50px;
          text-align: center;
          @media (max-width: 780px) {
            bottom: -60px;
          }
        }
        .bar-box {
          display: flex;
          position: relative;
          height: $barchart-height;
          text-align: center;
          justify-content: center;
          top: 5px;
          transition: all 0.2s ease;
          .bar {
            display: inline-block;
            width: 40px;
            align-self: flex-end;
            position: relative;
            margin: 0 5px;
            transition: all 0.2s ease;
            .nr {
              display: block;
              width: 100%;
              position: absolute;
              top: -30px;
            }
          }
          .bar1 {
            background: #fff;
            border:1px solid #000;
            
          }
          .bar2 {
            background: #929496;
            border:1px solid #929496;
          }
          &:hover {
            box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
            //background:#f9f9f9;
            //transform: scale(1.05);
            .bar {
            }
          }
        }
      }
    }
    .barchart-legend {
      display: block;
      width: 100%;
      margin-top: 70px;
      display: flex;
      justify-content: center;
      border-top: 3px solid #eee;
      padding-top: 10px;
      .legend-box {
        padding: 10px;
        margin: 0 10px;
      }
      .legend1 {
        span {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: #fff;
          border:1px solid #000;
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
      .legend2 {
        span {
          display: inline-block;
          width: 15px;
          height: 15px;
          background: #929496;
          border:1px solid #929496;
          position: relative;
          top: 2px;
          margin-right: 5px;
        }
      }
    }
  }
  .btn-form {
    padding: 10px 20px;
    height: auto;
    margin: 10px 0;
  }

}
</style>

<template>
  <div id="analysedetails">
    <div class="row headline">
      <h1 class="col-12">{{ skillinfo.name }}</h1>
    </div>

    <router-link class="btn blue back" :to="{ name: 'Analyse' }"><Icon :path="mdiChevronDoubleLeft" />Zurück</router-link>
    <h2 class="headline-h2">GESAMTSCORE</h2>
    <div class="row">
      <div class="progress-bar-outer col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <div class="progress-bar-inner">
          <div class="bar-100perc">
            <div class="nr">von 100 %</div>
          </div>
          <div class="bar-total" :style="`width:${distro.percent}%`" :class="clazz">
            <div class="nr">{{ distro.percent }} %</div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-center-mitarbeiter-collapse">
    <CollapsibleButton link="mitarbeiter" class="btn blue">Mitarbeiter mit diesem Skill</CollapsibleButton>
    </div>
    <Collapsible link="mitarbeiter">
      <PersonList :persons="allPersons" :skill="skillinfo" linked />
    </Collapsible>
    <h2 class="headline-h2">Punkteübersicht Skill</h2>
    <div class="row">
      <div class="cols-12 boxone" v-if="loaded">
        <div class="barchart-skill-outer">
          <div class="barchart-skill-inner">
            <div class="txt-box-y-ach"><span>Mitarbeiter</span></div>
            <div class="skala-left">
              <div class="skala-box skala30"><span class="nr">30</span></div>
              <div class="skala-box skala25"><span class="nr">25</span></div>
              <div class="skala-box skala20"><span class="nr">20</span></div>
              <div class="skala-box skala15"><span class="nr">15</span></div>
              <div class="skala-box skala10"><span class="nr">10</span></div>
              <div class="skala-box skala5"><span class="nr">5</span></div>
              <div class="skala-box skala0"><span class="nr">0</span></div>
            </div>
            <div class="box">
              <div class="nr-hori">0</div>
              <div class="bar-box">
                <div class="bar bar1" :style="`height:${distro.zero.dim1 * 10}px`">
                  <div class="nr">{{ distro.zero.dim1 }}</div>
                </div>
                <div class="bar bar2" :style="`height:${distro.zero.dim2 * 10}px`">
                  <div class="nr">{{ distro.zero.dim2 }}</div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="nr-hori">1</div>
              <div class="bar-box">
                <div class="bar bar1" :style="`height:${distro.one.dim1 * 10}px`">
                  <div class="nr">{{ distro.one.dim1 }}</div>
                </div>
                <div class="bar bar2" :style="`height:${distro.one.dim2 * 10}px`">
                  <div class="nr">{{ distro.one.dim2 }}</div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="nr-hori">2</div>
              <div class="bar-box">
                <div class="bar bar1" :style="`height:${distro.two.dim1 * 10}px`">
                  <div class="nr">{{ distro.two.dim1 }}</div>
                </div>
                <div class="bar bar2" :style="`height:${distro.two.dim2 * 10}px`">
                  <div class="nr">{{ distro.two.dim2 }}</div>
                </div>
              </div>
            </div>
            <div class="box">
              <div class="nr-hori">3</div>
              <div class="bar-box">
                <div class="bar bar1" :style="`height:${distro.three.dim1 * 10}px`">
                  <div class="nr">{{ distro.three.dim1 }}</div>
                </div>
                <div class="bar bar2" :style="`height:${distro.three.dim2 * 10}px`">
                  <div class="nr">{{ distro.three.dim2 }}</div>
                </div>
              </div>
            </div>
            <div class="txt-box-x-ach"><span>Punkte</span></div>
          </div>

          <div class="barchart-legend">
            <div class="legend-box legend1"><span></span> {{ skillinfo.dim1.name }}</div>
            <div class="legend-box legend2"><span></span> {{ skillinfo.dim2.name }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Collapsible from '../../components/util/Collapsible.vue';
import CollapsibleButton from '../../components/util/CollapsibleButton.vue';
import PersonList from '../../components/Person/PersonList.vue';
import { mdiChevronDoubleLeft, mdiAccountGroup   } from '@mdi/js';

export default defineComponent({
  components: {
    Collapsible,
    CollapsibleButton,
    PersonList,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const distro = ref({});
    const currentSkill = ref(router.currentRoute.value.params.id);
    const loaded = ref(false);
    const skillinfo = ref({});
    const allPersons = ref([]);
    const clazz = ref("");

    const load = () => {
      store.dispatch('fetchPointDistroForSkill', currentSkill.value).then((res) => {
        distro.value = res;
        loaded.value = true;
      });

      store.dispatch('fetchSkillInfo', currentSkill.value).then((res: SkillInfo) => {
        skillinfo.value = res;
        switch(res.group){
          case "Neuentwicklung":
            clazz.value = "neuentwicklung";
            break;
            case "Umsetzung":
            clazz.value = "umsetzung";
            break;
            case "Sonstige":
            clazz.value = "sonstige";
            break;
        }
      });

      store.dispatch('fetchCompententForSkill', currentSkill.value).then((res) => {
        allPersons.value = res;
      });
    };

    load();

    return { distro, loaded, skillinfo, allPersons, mdiChevronDoubleLeft, mdiAccountGroup,clazz   };
  },
});
</script>
