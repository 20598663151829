
import { computed, defineComponent } from 'vue';
import { useStore } from 'vuex';
export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    open: Boolean,
  },
  setup(props) {
    const store = useStore();

    store.state.collapsiblestate[props.link] = props.open || false;

    const isVisible = computed(() => store.state.collapsiblestate[props.link]);

    return {
      props,
      isVisible,
    };
  },
});
